/* eslint-disable */
export default function init() {

  if(jQuery('.wpcf7-hidden[value="hazte-cliente"]').length > 0){
    form_hazte_cliente();
  }  

  if(jQuery('.wpcf7-hidden[value="punto-recogida"]').length > 0){
    form_punto_recogida();
  }  
}

function form_hazte_cliente(){
  let contacto = jQuery('*[data-id="contacto"]');
  let empresa = jQuery('*[data-id="empresa"]');
  let actividad = jQuery('*[data-id="actividad"]');

  let fieldName = jQuery("#username");
  let fieldEmail = jQuery("#email");
  let fieldPhone = jQuery("#phone");

  let fieldCompany = jQuery("#company");
  let fieldIndustria = jQuery("#industria");
  let fieldPuntorecogida = jQuery("#puntorecogida");
  let fieldUbicacion = jQuery("#ubicacion");
  let fieldPais = jQuery("#pais");

  jQuery(".js-form-step1").on("click", function () {
    if (validateFormHazteClienteStep1()) {
      jQuery("#formstep").val("empresa");
      contacto.addClass("wpcf7cf-hidden").hide();
      empresa.removeClass("wpcf7cf-hidden").show();
      jQuery(".form-items-steps p").removeClass("active");
      jQuery(".form-items-steps p:nth-child(2)").addClass("active");

      let form = jQuery(this).closest('.block-form');
      $([document.documentElement, document.body]).animate({
        scrollTop: jQuery(form).offset().top - 74
      }, 1000);
    }
  });

  jQuery(".js-form-step2").on("click", function () {
    if (validateFormHazteClienteStep2()) {
      jQuery("#formstep").val("actividad");
      empresa.addClass("wpcf7cf-hidden").hide();
      actividad.removeClass("wpcf7cf-hidden").show();
      jQuery(".form-items-steps p").removeClass("active");
      jQuery(".form-items-steps p:nth-child(3)").addClass("active");

      let form = jQuery(this).closest('.block-form');
      $([document.documentElement, document.body]).animate({
        scrollTop: jQuery(form).offset().top -74
      }, 1000);
    }
  });

  jQuery(".js-back-form-step1").on("click", function () {
    jQuery("#formstep").val("contacto");
    contacto.addClass("wpcf7cf-hidden").show();
    empresa.removeClass("wpcf7cf-hidden").hide();
    jQuery(".form-items-steps p").removeClass("active");
    jQuery(".form-items-steps p:nth-child(1)").addClass("active");

    let form = jQuery(this).closest('.block-form');
    $([document.documentElement, document.body]).animate({
      scrollTop: jQuery(form).offset().top - 74
    }, 1000);
  });

  jQuery(".js-back-form-step2").on("click", function () {
    jQuery("#formstep").val("empresa");
    empresa.addClass("wpcf7cf-hidden").show();
    actividad.removeClass("wpcf7cf-hidden").hide();
    jQuery(".form-items-steps p").removeClass("active");
    jQuery(".form-items-steps p:nth-child(2)").addClass("active");

    let form = jQuery(this).closest('.block-form');
    $([document.documentElement, document.body]).animate({
      scrollTop: jQuery(form).offset().top -74
    }, 1000);
    
  });

  jQuery('.block-form select').on('change', function() {
    if (jQuery(this).val() != '') {
      jQuery(this).addClass('selected');
    } else {
      jQuery(this).removeClass('selected');
    }
  });

  function validateFormHazteClienteStep1() {
    if (fieldName.val() === "") {
      showError(fieldName);
    }
    else{
      removeError(fieldName);
    }
  
    if (fieldPhone.val() === "") {
      showError(fieldPhone);
    }
    else{
      removeError(fieldPhone);
    }
  
    if (IsEmail(fieldEmail.val()) === false) {
      showError(fieldEmail);
    }
    else{
      removeError(fieldEmail);
    }
  
    if (fieldName.val() !== "" && fieldPhone.val() !== "" && IsEmail(fieldEmail.val()) !== false) {
      return true;
    }
  
    return false;
  }

  function validateFormHazteClienteStep2() {
    if (fieldCompany.val() === "") {
      showError(fieldCompany);
    }
    else{
      removeError(fieldCompany);
    }
  
    if (fieldIndustria.val() === "") {
      showError(fieldIndustria);
    }
    else{
      removeError(fieldIndustria);
    }
  
    if (fieldPuntorecogida.val() === "") {
      showError(fieldPuntorecogida);
    }
    else{
      removeError(fieldPuntorecogida);
    }
  
    if (fieldUbicacion.val() === "") {
      showError(fieldUbicacion);
    }
    else{
      removeError(fieldUbicacion);
    }
  
    if (fieldPais.val() === "") {
      showError(fieldPais);
    }
    else{
      removeError(fieldPais);
    }
  
    let fieldProvince = jQuery('.state_select.show .wpcf7-state_select');
  
    if (fieldProvince.val() === "") {
      showError(fieldProvince);
    }
    else{
      removeError(fieldProvince);
    }
  
    let fieldCity = jQuery('.city_select.show .wpcf7-city_select');
  
    if (fieldCity.val() === "") {
      showError(fieldCity);
    }
    else{
      removeError(fieldCity);
    }
  
    if (
      fieldCompany.val() !== "" &&
      fieldIndustria.val() !== "" &&
      fieldPuntorecogida.val() !== "" &&
      fieldUbicacion.val() !== "" &&
      fieldPais.val() !== "" &&
      fieldProvince.val() !== "" &&
      fieldCity.val() !== ""
    ) {
      return true;
    }
  
    return false;
  }
}

function form_punto_recogida(){
  let contacto = jQuery('*[data-id="contacto"]');
  let tienda = jQuery('*[data-id="tienda"]');
  let horario = jQuery('*[data-id="horario"]');
  let adicional = jQuery('*[data-id="adicional"]');

  jQuery(".js-form-step1").on("click", function () {
    if (validateFormPuntoRecogidaStep1()) {
      jQuery("#formstep").val("tienda");
      contacto.addClass("wpcf7cf-hidden").hide();
      tienda.removeClass("wpcf7cf-hidden").show();
      jQuery(".form-items-steps p").removeClass("active");
      jQuery(".form-items-steps p:nth-child(2)").addClass("active");

      let form = jQuery(this).closest('.block-form');
      $([document.documentElement, document.body]).animate({
        scrollTop: jQuery(form).offset().top - 74
      }, 1000);
    }
  });

  jQuery(".js-form-step2").on("click", function () {
    if (validateFormPuntoRecogidaStep2()) {
      jQuery("#formstep").val("horario");
      tienda.addClass("wpcf7cf-hidden").hide();
      horario.removeClass("wpcf7cf-hidden").show();
      jQuery(".form-items-steps p").removeClass("active");
      jQuery(".form-items-steps p:nth-child(3)").addClass("active");

      let form = jQuery(this).closest('.block-form');
      $([document.documentElement, document.body]).animate({
        scrollTop: jQuery(form).offset().top -74
      }, 1000);
    }
  });

  jQuery(".js-form-step3").on("click", function () {
    if (validateFormPuntoRecogidaStep3()) {
      jQuery("#formstep").val("adicional");
      horario.addClass("wpcf7cf-hidden").hide();
      adicional.removeClass("wpcf7cf-hidden").show();
      jQuery(".form-items-steps p").removeClass("active");
      jQuery(".form-items-steps p:nth-child(4)").addClass("active");

      let form = jQuery(this).closest('.block-form');
      $([document.documentElement, document.body]).animate({
        scrollTop: jQuery(form).offset().top -74
      }, 1000);
    }
  });

  jQuery(".js-back-form-step1").on("click", function () {
    jQuery("#formstep").val("contacto");
    contacto.addClass("wpcf7cf-hidden").show();
    tienda.removeClass("wpcf7cf-hidden").hide();
    jQuery(".form-items-steps p").removeClass("active");
    jQuery(".form-items-steps p:nth-child(1)").addClass("active");

    let form = jQuery(this).closest('.block-form');
    $([document.documentElement, document.body]).animate({
      scrollTop: jQuery(form).offset().top - 74
    }, 1000);
  });

  jQuery(".js-back-form-step2").on("click", function () {
    jQuery("#formstep").val("tienda");
    tienda.addClass("wpcf7cf-hidden").show();
    horario.removeClass("wpcf7cf-hidden").hide();
    jQuery(".form-items-steps p").removeClass("active");
    jQuery(".form-items-steps p:nth-child(2)").addClass("active");

    let form = jQuery(this).closest('.block-form');
    $([document.documentElement, document.body]).animate({
      scrollTop: jQuery(form).offset().top -74
    }, 1000);
    
  });

  jQuery(".js-back-form-step3").on("click", function () {
    jQuery("#formstep").val("horario");
    horario.addClass("wpcf7cf-hidden").show();
    adicional.removeClass("wpcf7cf-hidden").hide();
    jQuery(".form-items-steps p").removeClass("active");
    jQuery(".form-items-steps p:nth-child(3)").addClass("active");

    let form = jQuery(this).closest('.block-form');
    $([document.documentElement, document.body]).animate({
      scrollTop: jQuery(form).offset().top -74
    }, 1000);
    
  });

  jQuery('.block-form select').on('change', function() {
    if (jQuery(this).val() != '') {
      jQuery(this).addClass('selected');
    } else {
      jQuery(this).removeClass('selected');
    }
  });

  function validateFormPuntoRecogidaStep1() {
    let has_error = false;

    let fieldFirstname = jQuery("#firstname");
    let fieldLastname = jQuery("#lastname");
    let fieldEmail = jQuery("#email");
    let fieldPhone = jQuery("#phone");

    if (fieldFirstname.val() === "") {
      has_error = true;
      showError(fieldFirstname);
    }
    else{
      removeError(fieldFirstname);
    }

    if (fieldLastname.val() === "") {
      has_error = true;
      showError(fieldLastname);
    }
    else{
      removeError(fieldLastname);
    }

    if (fieldPhone.val() === "") {
      has_error = true;
      showError(fieldPhone);
    }
    else{
      removeError(fieldPhone);
    }

    if (IsEmail(fieldEmail.val()) === false) {
      has_error = true;
      showError(fieldEmail);
    }
    else{
      removeError(fieldEmail);
    }

    return !has_error;
  }

  function validateFormPuntoRecogidaStep2() {
    let has_error = false;

    let fieldStorename = jQuery("#store_name");
    let fieldCif = jQuery("#cif");
    let fieldAddress = jQuery("#street");
    let fieldNumber = jQuery("#number");
    let fieldCP = jQuery("#cp");

    let fieldCountry = jQuery("#pais");

    if(fieldCountry.length > 0 && fieldCountry.val() === ""){
      has_error = true;
      showError(fieldCountry);
    }

    let fieldProvince = jQuery('.state_select.show .wpcf7-state_select');
  
    if (fieldProvince.length > 0 && fieldProvince.val() === "") {
      showError(fieldProvince);
    }
    else{
      removeError(fieldProvince);
    }
  
    let fieldCity = jQuery('.city_select.show .wpcf7-city_select');
  
    if (fieldCity.length > 0 && fieldCity.val() === "") {
      showError(fieldCity);
    }
    else{
      removeError(fieldCity);
    }

    let fieldTypeEstablishment = jQuery("#type_establishment");
    let fieldMeters = jQuery("#meters");

    if (fieldStorename.val() === "") {
      has_error = true;
      showError(fieldStorename);
    }
    else{
      removeError(fieldStorename);
    }

    if (fieldCif.val() === "") {
      has_error = true;
      showError(fieldCif);
    }
    else{
      removeError(fieldCif);
    }

    if (fieldAddress.val() === "") {
      has_error = true;
      showError(fieldAddress);
    }
    else{
      removeError(fieldAddress);
    }

    if (fieldNumber.val() === "") {
      has_error = true;
      showError(fieldNumber);
    }
    else{
      removeError(fieldNumber);
    }

    if (fieldCP.val() === "") {
      has_error = true;
      showError(fieldCP);
    }
    else{
      removeError(fieldCP);
    }

    if (fieldTypeEstablishment.val() === "") {
      has_error = true;
      showError(fieldTypeEstablishment);
    }
    else{
      removeError(fieldTypeEstablishment);
    }

    if (fieldMeters.val() === "") {
      has_error = true;
      showError(fieldMeters);
    }
    else{
      removeError(fieldMeters);
    }

    return !has_error;
  }

  function validateFormPuntoRecogidaStep3() {
    let has_error = false;

    let weekday_opening = jQuery("#weekday_opening");

    if (weekday_opening.val() === "") {
      has_error = true;
      showError(weekday_opening);
    }
    else{
      removeError(weekday_opening);
    }

    let opening_hour_start = jQuery("#opening_hour_start");

    if (opening_hour_start.val() === "") {
      has_error = true;
      showError(opening_hour_start);
    }
    else{
      removeError(opening_hour_start);
    }

    let opening_hour_end = jQuery("#opening_hour_end");

    if (opening_hour_end.val() === "") {
      has_error = true;
      showError(opening_hour_end);
    }
    else{
      removeError(opening_hour_end);
    }

    let break_lunch = jQuery("#break_lunch");

    if (break_lunch.val() === "") {
      has_error = true;
      showError(break_lunch);
    }
    else{
      removeError(break_lunch);

      if(break_lunch.val() !== "No" && break_lunch.val() !== "Non" && break_lunch.val() !== "Não"){
        let break_horas = jQuery("#break_horas");

        if (break_horas.val() === "") {
          has_error = true;
          showError(break_horas);
        }
        else{
          removeError(break_horas);
        }

        let break_horas_end = jQuery("#break_horas_end");

        if (break_horas_end.val() === "") {
          has_error = true;
          showError(break_horas_end);
        }
        else{
          removeError(break_horas_end);
        }
      }
    }

    return !has_error;
  }

  function showError(field) {
    if(!field.hasClass("wpcf7-not-valid")){
      field.addClass("wpcf7-not-valid");

      const lang = $('html').attr('lang');
      if(lang == 'en-US') {
        field.after(
          '<span class="wpcf7-not-valid-tip" aria-hidden="true">Please fill in this field or validate that it is correct.</span>'
        );
      }
      else {
        field.after(
          '<span class="wpcf7-not-valid-tip" aria-hidden="true">Por favor, rellena este campo o valida que es correcto.</span>'
        );
      }
    }
  }

  function removeError(field) {
    if(field.hasClass("wpcf7-not-valid")){
      field.removeClass("wpcf7-not-valid");
      field.next('.wpcf7-not-valid-tip').remove();
    }
  }

  function IsEmail(email) {
    var regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  }
}

function showError(field) {
  if(!field.hasClass("wpcf7-not-valid")){
    field.addClass("wpcf7-not-valid");

    const lang = $('html').attr('lang');
    if(lang == 'en-US') {
      field.after(
        '<span class="wpcf7-not-valid-tip" aria-hidden="true">Please fill in this field or validate that it is correct.</span>'
      );
    }
    else if(lang == 'it-IT') {
      field.after(
        '<span class="wpcf7-not-valid-tip" aria-hidden="true">Per favore, compila questo campo o verifica che sia corretto.</span>'
      );
    }
    else if(lang == 'fr-FR') {
      field.after(
        "<span class='wpcf7-not-valid-tip' aria-hidden='true'>Veuillez remplir ce champ ou vérifier qu'il est correct.</span>"
      );
    }
    else if(lang == 'pt-pt' || lang == 'pt') {
      field.after(
        "<span class='wpcf7-not-valid-tip' aria-hidden='true'>Por favor, preencha este campo ou valide que está correto.</span>"
      );
    }
    else {
      field.after(
        '<span class="wpcf7-not-valid-tip" aria-hidden="true">Por favor, rellena este campo o valida que es correcto.</span>'
      );
    }
  }
}

function removeError(field) {
  if(field.hasClass("wpcf7-not-valid")){
    field.removeClass("wpcf7-not-valid");
    field.next('.wpcf7-not-valid-tip').remove();
  }
}

function IsEmail(email) {
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!regex.test(email)) {
    return false;
  } else {
    return true;
  }
}