/* eslint-disable */
// import function to register Swiper custom elements
import jQuery from 'jquery';
import { register } from "swiper/element/bundle";

export default function init() {
  register();

  if (jQuery(".testimonialsSwiper").length) {
    // swiper element
    const swiperElTestimonials = document.querySelectorAll(".testimonialsSwiper");
    swiperElTestimonials.forEach((swiperElTestimonial) => {
      const parent = swiperElTestimonial.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");

      // swiper parameters
      const swiperParamsTestimonials = {
        slidesPerView: 1,
        watchOverflow: true,
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        autoHeight: true,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElTestimonial, swiperParamsTestimonials);

      // and now initialize it
      swiperElTestimonial.initialize();
    });
  }

  if (jQuery(".logosTestimonialSwiper").length) {
    // swiper element
    const swiperElLogosTestimonials = document.querySelectorAll(".logosTestimonialSwiper");
    swiperElLogosTestimonials.forEach((swiperElLogoTestimonial) => {

      const num_items = swiperElLogoTestimonial.getAttribute('data-items');
      let slidesPerViewDesktop = (num_items < 7) ? num_items : 7;
      let slidesPerViewTablet = (num_items < 5) ? num_items : 5;
      let slidesPerViewMobile = (num_items < 3) ? num_items : 3;

      // swiper parameters
      const swiperParamsLogoTestimonials = {
        slidesPerView: slidesPerViewMobile,
        watchOverflow: true,
        loop: true,
        spaceBetween: 16,
        centerInsufficientSlides: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        breakpoints: {
          640: {
            slidesPerView: slidesPerViewTablet,
          },
          767: {
            slidesPerView: slidesPerViewDesktop,
            spaceBetween: 32,
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElLogoTestimonial, swiperParamsLogoTestimonials);

      // and now initialize it
      swiperElLogoTestimonial.initialize();
    });
  }

  if (jQuery(".swiper-equal-features").length) {
    const featuresSwiperSlider = document.querySelectorAll(".swiper-equal-features");
    featuresSwiperSlider.forEach((swiperElfeatures) => {
      const parent = swiperElfeatures.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_feature = container.querySelector(".equal-swiper-pagination");

      const swiperParamsfeatures = {
        spaceBetween: 24,
        slidesPerView: 1,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
            pagination: {
              enabled: true,
            }
          },
          1200: {
            slidesPerView: 4,
            pagination: {
              enabled: true,
            }
          },
        },
        pagination: {
          enabled: false,
          el: pagination_feature,
          clickable: true,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElfeatures.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElfeatures.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElfeatures, swiperParamsfeatures);

      // and now initialize it
      swiperElfeatures.initialize();
    })
  }

  if (jQuery(".blog_cards_repeater_slider").length) {
    // swiper element
    const swiperBlogCards = document.querySelectorAll(".blog_cards_repeater_slider");
    swiperBlogCards.forEach((swiperBlogCard) => {
      const parent = swiperBlogCard.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const blogCards_pagination = container.querySelector(".blog-cards-repeater-pagination");
      // swiper parameters
      const swiperParamsBlogCards = {
        spaceBetween: 24,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          580: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          889: {
            slidesPerView: 3,
            pagination: {
              enabled: true,
            }
          },
          1040: {
            slidesPerView: 4,
            pagination: {
              enabled: true,
            }
          },
        },
        pagination: {
          enabled: false,
          el: blogCards_pagination,
          clickable: true,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperBlogCard.classList.add('nav-lock');
          },
          unlock: function () {
            swiperBlogCard.classList.remove('nav-lock');
          }
        }
      };

      Object.assign(swiperBlogCard, swiperParamsBlogCards);
      swiperBlogCard.initialize();
    })
  }

  if (jQuery(".heroSwiper").length) {
    // swiper element
    const swiperHero = document.querySelector(".heroSwiper");

    // swiper parameters
    const swiperParamsHero = {
      watchOverflow: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: ".hero-swiper-pagination",
        clickable: true,
      },
      on: {
        slideChange: function () {
          //console.log(this.realIndex, this.activeIndex);
        },
      },
    };

    // now we need to assign all parameters to Swiper element
    Object.assign(swiperHero, swiperParamsHero);

    // and now initialize it
    swiperHero.initialize();
  }
  
  if (jQuery(".hero-thumbs").length) {
    const heroThumbsSwiperSlider = document.querySelectorAll(".hero-thumbs");
    heroThumbsSwiperSlider.forEach((swiperElHeroThumbs) => {
      const parent = swiperElHeroThumbs.parentElement;
      const pagination_hero_thumbs = parent.querySelector(".hero-thumbs-swiper-pagination");

      const swiperParamsHeroThumbs = {
        spaceBetween: 24,
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
          577: {
            slidesPerView: 2,
            pagination: {
              enabled: true,
            }
          },
          981: {
            slidesPerView: 3,
            pagination: {
              enabled: false,
            }
          },
        },
        pagination: {
          enabled: true,
          el: pagination_hero_thumbs,
          clickable: true,
        },
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElHeroThumbs, swiperParamsHeroThumbs);

      // and now initialize it
      swiperElHeroThumbs.initialize();
    })
  }

  if (jQuery(".alcanceSwiper").length) {
    const alcanceSwiperSlider = document.querySelectorAll(".alcanceSwiper");
    alcanceSwiperSlider.forEach((swiperElAlcance) => {
      const parent = swiperElAlcance.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_alcance = container.querySelector(".alcance-swiper-pagination");

      const n_items = jQuery(swiperElAlcance).find('swiper-slide').length;
      let slides_per_view = 3;
      let slides_per_view_tablet = 2;
      if(n_items < 3){
        slides_per_view = n_items;
      }

      if(n_items < 2){
        slides_per_view_tablet = n_items;
      }

      const swiperParamsAlcance = {
        spaceBetween: 24,
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
          768: {
            slidesPerView: slides_per_view_tablet,
            pagination: {
              enabled: true,
            }
          },
          1200: {
            slidesPerView: slides_per_view,
            pagination: {
              enabled: true,
            }
          },
        },
        pagination: {
          enabled: false,
          el: pagination_alcance,
          clickable: true,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElAlcance.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElAlcance.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElAlcance, swiperParamsAlcance);

      // and now initialize it
      swiperElAlcance.initialize();
    })
  }

  if (jQuery(".swiper-delivery-features").length) {
    const deliverySwiperSlider = document.querySelectorAll(".swiper-delivery-features");
    deliverySwiperSlider.forEach((swiperEldelivery) => {
      const parent = swiperEldelivery.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_delivery = container.querySelector(".delivery-swiper-pagination");
      // swiper parameters
      const swiperParamsdelivery = {
        spaceBetween: 24,
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
          768: {
            slidesPerView: 2,
            pagination: {
              enabled: true,
            }
          },
          1200: {
            slidesPerView: 3,
            pagination: {
              enabled: true,
            }
          },
        },
        pagination: {
          enabled: false,
          el: pagination_delivery,
          clickable: true,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperEldelivery.classList.add('nav-lock');
          },
          unlock: function () {
            swiperEldelivery.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEldelivery, swiperParamsdelivery);
      // and now initialize it
      swiperEldelivery.initialize();
    });
  }

  if (jQuery(".cardSwiper").length) {
    const swiperCards = document.querySelectorAll(".cardSwiper");
    swiperCards.forEach((swiperElcard) => {
      const parent = swiperElcard.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_card = container.querySelector(".card-swiper-pagination");

      const swiperParamsCards = {
        slidesPerView: 1,
        spaceBetween: 16,
        centerInsufficientSlides: true,
        breakpoints: {
          690: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            pagination: {
              enabled: true,
            },
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 24,
            pagination: {
              enabled: true,
            },
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 32,
            pagination: {
              enabled: true,
            },
          },
        },
        pagination: {
          el: pagination_card,
          clickable: true,
          enabled: false,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElcard.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElcard.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElcard, swiperParamsCards);

      // and now initialize it
      swiperElcard.initialize();
    })
  }

  if (jQuery(".servicesSwiper").length) {
    // swiper element
    const swiperElServices = document.querySelectorAll(".servicesSwiper");
    swiperElServices.forEach((swiperElService) => {
      const parent = swiperElService.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_services = container.querySelector(".services-swiper-pagination");

      const n_items = jQuery(swiperElService).attr('data-n');
      const items_desktop = (n_items > 2) ? 3 : 2;

      // swiper parameters
      const swiperParamsServices = {
        spaceBetween: 16,
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
          500: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            spaceBetween: 24,
            slidesPerView: 2,
            pagination: {
              enabled: true,
            }
          },
          1200: {
            slidesPerView: items_desktop,
            pagination: {
              enabled: true,
            }
          },
        },
        pagination: {
          enabled: false,
          el: pagination_services,
          clickable: true,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElService.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElService.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElService, swiperParamsServices);

      // and now initialize it
      swiperElService.initialize();
    })
  }

  if (jQuery(".productsSwiper").length) {
    // swiper element
    const swiperElProducts = document.querySelectorAll(".productsSwiper");
    swiperElProducts.forEach((swiperElProduct) => {
      const parent = swiperElProduct.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_products = container.querySelector(".products-swiper-pagination");

      const swiperParamsProducts = {
        spaceBetween: 16,
        slidesPerView: 1,
        breakpoints: {
          690: {
            slidesPerView: 2,
          },
          768: {
            spaceBetween: 24,
            slidesPerView: 3,
            pagination: {
              enabled: true
            },
          },
          1024: {
            slidesPerView: 4,
            pagination: {
              enabled: true
            },
          },
        },
        pagination: {
          el: pagination_products,
          clickable: true,
          enabled: false
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElProduct.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElProduct.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElProduct, swiperParamsProducts);

      // and now initialize it
      swiperElProduct.initialize();
    })
  }

  if (jQuery(".stepsSwiper").length) {
    // swiper element
    const swiperElSteps = document.querySelectorAll(".stepsSwiper");
    swiperElSteps.forEach((swiperElStep) => {
      const parent = swiperElStep.parentElement;
      const next_step = parent.querySelector(".swiper-button-next");
      const prev_step = parent.querySelector(".swiper-button-prev");
      
      const container = parent.parentElement;
      const pagination_steps = container.querySelector(".steps-swiper-pagination");
      // swiper parameters
      const swiperParamsSteps = {
        spaceBetween: 16,
        slidesPerView: 1,
        watchOverflow: true,
        navigation: {
          nextEl: next_step,
          prevEl: prev_step,
        },
        breakpoints: {
          768: {
            pagination: {
              enabled: true,
            }
          },
        },
        pagination: {
          enabled: false,
          el: pagination_steps,
          clickable: true,
        },
        on: {
          lock: function () {
            swiperElStep.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElStep.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElStep, swiperParamsSteps);

      // and now initialize it
      swiperElStep.initialize();
    })
  }

  if (jQuery(".precisionSwiper").length) {
    // swiper element
    const swiperElPrecision = document.querySelectorAll(".precisionSwiper");
    swiperElPrecision.forEach((swiperElPrecisionItem) => {
      const parent = swiperElPrecisionItem.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");

      const container = parent.parentElement;
      const pagination_precision = container.querySelector(".precision-swiper-pagination");

      let swiperParamsPrecision = null;
      // swiper parameters
      swiperParamsPrecision = {
        spaceBetween: 16,
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
          768: {
            spaceBetween: 24,
            slidesPerView: 2,
            pagination: {
              enabled: true,
            },
          },
          1200: {
            slidesPerView: 3,
            pagination: {
              enabled: true,
            },
          },
        },
        pagination: {
          el: pagination_precision,
          clickable: true,
          enabled: false,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElPrecisionItem.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElPrecisionItem.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElPrecisionItem, swiperParamsPrecision);

      // and now initialize it
      swiperElPrecisionItem.initialize();
    })
  }

  if (jQuery(".caseStudiesSwiper").length) {
    // swiper element
    const swiperElcaseStudies = document.querySelectorAll(".caseStudiesSwiper");
    swiperElcaseStudies.forEach((swiperElcaseStudiesItem) => {
      const parent = swiperElcaseStudiesItem.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");

      const container = parent.parentElement;
      const pagination_caseStudies = container.querySelector(".caseStudies-swiper-pagination");

      let swiperParamscaseStudies = null;
      // swiper parameters
      swiperParamscaseStudies = {
        spaceBetween: 16,
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
          500: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            spaceBetween: 24,
            slidesPerView: 2,
            pagination: {
              enabled: true,
            },
          },
          1200: {
            slidesPerView: 3,
            pagination: {
              enabled: true,
            },
            spaceBetween: 24,
          },
        },
        pagination: {
          el: pagination_caseStudies,
          clickable: true,
          enabled: false,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElcaseStudiesItem.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElcaseStudiesItem.classList.remove('nav-lock');
          }
        }
      };
      
      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElcaseStudiesItem, swiperParamscaseStudies);

      // and now initialize it
      swiperElcaseStudiesItem.initialize();
    })
  }

  if (jQuery(".iconsSwiper").length) {
    // swiper element
    const swiperElIcons = document.querySelectorAll(".iconsSwiper");
    swiperElIcons.forEach((swiperElIcon) => {
      const parent = swiperElIcon.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");

      const container = parent.parentElement;
      const pagination_icons = container.querySelector(".icons-swiper-pagination");
      
      // swiper parameters
      const swiperParamsIcons = {
        spaceBetween: 16,
        slidesPerView: 1,
        watchOverflow: true,
        centerInsufficientSlides: true,
        breakpoints: {
          550: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
            pagination: {
              enabled: true,
            }
          },
          950: {
            pagination: {
              enabled: true,
            },
            slidesPerView: 3,
          },
          1230: {
            pagination: {
              enabled: true,
            },
            slidesPerView: 4,
          },
        },
        pagination: {
          enabled: false,
          el: pagination_icons,
          clickable: true,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElIcon.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElIcon.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElIcon, swiperParamsIcons);

      // and now initialize it
      swiperElIcon.initialize();
    })
  }

  if (jQuery(".relatedPostsSwiper").length) {
    // swiper element
    const swiperRelatedPosts = document.querySelectorAll(".relatedPostsSwiper");
    swiperRelatedPosts.forEach((swiperRelatedPost) => {
      const parent = swiperRelatedPost.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_related = container.querySelector('.related-post-pagination');

      const swiperParamsRelatedPosts = {
        spaceBetween: 16,
        slidesPerView: 1,
        watchOverflow: true,
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        pagination: {
          enabled: false,
          el: pagination_related,
          clickable: true,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            spaceBetween: 24,
            slidesPerView: 3,
            pagination: {
              enabled: true,
            }
          },
          1200: {
            slidesPerView: 4,
            pagination: {
              enabled: true,
            }
          },
        },
        on: {
          lock: function () {
            swiperRelatedPost.classList.add('nav-lock');
          },
          unlock: function () {
            swiperRelatedPost.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperRelatedPost, swiperParamsRelatedPosts);

      // and now initialize it
      swiperRelatedPost.initialize();
    })
  }

  if (jQuery(".paackstoriesSwiper").length) {
    // swiper element
    const swiperElPaackStories = document.querySelectorAll(".paackstoriesSwiper");

    swiperElPaackStories.forEach((swiperElPaackStory) => {
      const parent = swiperElPaackStory.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_paackstories = container.querySelector(".swiper-pagination-paackstories");

      // swiper parameters
      const swiperParamsPaackStories = {
        spaceBetween: 20,
        slidesPerView: 1,
        watchOverflow: true,
        pagination: {
          enabled: false,
          el: pagination_paackstories,
          clickable: true,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
            pagination: {
              enabled: true,
            }
          },
          1200: {
            slidesPerView: 4,
            pagination: {
              enabled: true,
            }
          },
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElPaackStory.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElPaackStory.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElPaackStory, swiperParamsPaackStories);

      // and now initialize it
      swiperElPaackStory.initialize();
    })
  }

  if (jQuery(".borderCardsSwiper").length) {
    // swiper element
    const swiperElBorderCards = document.querySelectorAll(".borderCardsSwiper");

    swiperElBorderCards.forEach((swiperElBorderCard) => {
      const parent = swiperElBorderCard.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");

      const container = parent.parentElement;
      const pagination_border_cards = container.querySelector(".swiper-pagination-border-cards");

      // swiper parameters
      const swiperParamsBorderCards = {
        spaceBetween: 16,
        slidesPerView: 1,
        watchOverflow: true,
        pagination: {
          enabled: false,
          el: pagination_border_cards,
          clickable: true,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
            pagination: {
              enabled: false,
            },
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
            pagination: {
              enabled: false,
            },
          },
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            swiperElBorderCard.classList.add('nav-lock');
          },
          unlock: function () {
            swiperElBorderCard.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperElBorderCard, swiperParamsBorderCards);

      // and now initialize it
      swiperElBorderCard.initialize();
    })
  }

  if (jQuery(".logosSwiper").length) {
    // swiper element
    const logosEl = document.querySelectorAll(".logosSwiper");
    logosEl.forEach((logoEl) => {
      const parent = logoEl.parentElement;
      const prev_element = parent.querySelector(".swiper-button-prev");
      const next_element = parent.querySelector(".swiper-button-next");
      
      const container = parent.parentElement;
      const pagination_logos = container.querySelector(".swiper-pagination-logos");

      // swiper parameters
      const swiperParamsLogos = {
        spaceBetween: 48,
        slidesPerView: 1,
        watchOverflow: true,
        pagination: {
          enabled: false,
          el: pagination_logos,
          clickable: true,
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
            pagination: {
              enabled: true,
            },
          },
          1007: {
            slidesPerView: 4,
            pagination: {
              enabled: true,
            },
          },
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        on: {
          lock: function () {
            logoEl.classList.add('nav-lock');
          },
          unlock: function () {
            logoEl.classList.remove('nav-lock');
          }
        }
      };

      // now we need to assign all parameters to Swiper element
      Object.assign(logoEl, swiperParamsLogos);

      // and now initialize it
      logoEl.initialize();
    })
  }
}