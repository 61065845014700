export default function init() {
  jQuery('a[href^="#"]').on('click',function(e) {
    e.preventDefault();

    var data_lity = $(this).attr('data-lity');
    if (typeof data_lity !== 'undefined' && data_lity !== false) return;

    var target = $(this).attr("href");
    if($(target).length > 0){
      $('html,body').animate({scrollTop: $(target).offset().top},'slow');
    }
  });
}
