/* eslint-disable */
// import function to register Swiper custom elements
import anime from 'animejs/lib/anime.es.js';

export default function init() {

  // flower-animation
  const flower_animation = $('.flower-animation');

  const observer_flower = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const $svg = $(entry.target);
        const $bottom_left = $svg.find('path.bottom_left');
        const $bottom_right = $svg.find('path.bottom_right');
        const $top_left = $svg.find('path.top_left');
        const $top_right = $svg.find('path.top_right');

        anime({
          targets: $bottom_left.toArray(),
          translateX: ['-50%', 0],
          easing: 'linear',
          duration: 600,
        });

        anime({
          targets: $bottom_right.toArray(),
          translateY: ['50%', 0],
          easing: 'linear',
          duration: 600,
        });

        anime({
          targets: $top_right.toArray(),
          translateX: ['-50%', 0],
          easing: 'linear',
          duration: 600,
        });
        
        anime({
          targets: $top_left.toArray(),
          translateY: ['-50%', 0],
          easing: 'linear',
          duration: 600,
        })
        
        observer.unobserve(entry.target); // Dejar de observar una vez que la animación se ha ejecutado
      }
    });
  }, { threshold: 0.1 });

  flower_animation.each(function(){
    observer_flower.observe(this);
  })

  // Draw animation
  const elements_drawing = $('.line-drawing');
  elements_drawing.css({'visibility':'hidden'});

  const observer_drawing = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio === 1) {
        const $svg = $(entry.target);
        const $elementsToAnimate = $svg.find('path');

        anime({
          targets: $elementsToAnimate.toArray(),
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'linear',
          duration: 800,
          direction: 'alternate',
          loop: false,
          begin: function(){
            $svg.css({'visibility':'visible'});
          }
        });
        
        observer.unobserve(entry.target); // Dejar de observar una vez que la animación se ha ejecutado
      }
    });
  }, { threshold: 1 });

  elements_drawing.each(function(){
    observer_drawing.observe(this);
  })

  // Cross service animation
  const element_cross = $('.cross-service-flower');
  element_cross.css({'visibility':'hidden'});

  const observer_cross = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio >= 0.4) {
        const $svg = $(entry.target);
        const $elementsToAnimate = $svg.find('path');
        $elementsToAnimate.css({'transform-origin': 'center'});

        anime({
          targets: $elementsToAnimate.toArray(),
          rotate: '180deg',
          duration: 500,
          delay: 100,
          opacity: [0,1],
          easing: 'linear',
          begin: function(){
            $svg.css({'visibility':'visible'});
          }
        });
        
        observer.unobserve(entry.target); // Dejar de observar una vez que la animación se ha ejecutado
      }
    });
  }, { threshold: 0.4 });

  element_cross.each(function(){
    observer_cross.observe(this);
  })
}