/* eslint-disable */
import AOS from "aos";
import "../vendor/jquery.magnific-popup.min";
import menu from "../modules/menu.js";
import "@grubersjoe/slide-menu/dist/slide-menu";
import footer from "../modules/footer.js";
import slider from "../modules/slider.js";
import anchor from "../modules/anchor.js";
import video from "../modules/video.js";
import forms from "../modules/forms.js";
import banner from "../modules/banner.js";
import truncate_text from "../modules/truncate_text.js";
import spacer from "../modules/spacer.js";
import cobertura from "../modules/cobertura.js";
import nav_blog from "../modules/nav_blog.js";
import custom_selects_location_form from "../modules/custom_selects_location_form.js";
import btn_top from "../modules/btn_top.js";
import animate_svg from "../modules/animate_svg.js";

export default {
  init() {
    // JavaScript to be fired on all pages
    footer();
    cobertura();
    slider();
    anchor();
    video();
    forms();
    banner();
    truncate_text();
    spacer();
    nav_blog();
    custom_selects_location_form();
    btn_top();
    animate_svg();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    menu();
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: 'mobile'
    });

    jQuery(".smart-page-loader").hide();
  },
};
