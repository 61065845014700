export default function init() {
  jQuery('.truncate').each(function(){
    const truncateEl = jQuery(this).get(0);
    const truncateRect = truncateEl.getBoundingClientRect();
    truncateEl.style.setProperty("--truncate-height", `${truncateRect.height}px`);
  });

  jQuery('.read-more-truncate-text').on('click',function(){
      const truncate_container = jQuery(this).closest('.truncate-container');
      
      const truncate_text_content = truncate_container.find('.truncate');
      const truncate__inner = truncate_text_content.find('.truncate__inner');

      truncate_text_content.removeClass('truncate--line-clamped');
      const truncateEl = truncate_text_content.get(0);
      const truncateInnerEl = truncate__inner.get(0);

      window.requestAnimationFrame(() => {
        const truncateInnerRect = truncateInnerEl.getBoundingClientRect();
        truncateEl.style.setProperty("--truncate-height-expanded", `${truncateInnerRect.height}px`);
        truncate_text_content.addClass('truncate--expanded');
      });

      force_styles(truncate_text_content[0]);

      jQuery(this).hide();
  });
}

function force_styles(element) {
  console.log(element)
  // Fuerza los estilos en Safari
  element.style.display='none';
  element.offsetHeight;
  element.style.display='';
}